import React from "react"

import MasterLayout from "../components/MasterLayout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <MasterLayout>
    <SEO title="404: Not found" keywords={[`mowede`, `datawise`]} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </MasterLayout>
)

export default NotFoundPage
